import { gsap } from "gsap";
window.gsap=gsap;
/*
import { PixiPlugin } from "gsap/PixiPlugin";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Physics2DPlugin } from "gsap/Physics2DPlugin";
import { PhysicsPropsPlugin } from "gsap/PhysicsPropsPlugin";
import { SplitText } from "gsap/SplitText";
gsap.registerPlugin(PixiPlugin, ScrollToPlugin, Physics2DPlugin, PhysicsPropsPlugin, SplitText);
PixiPlugin.registerPIXI(PIXI);
gsap.registerPlugin(PixiPlugin);
gsap.registerPlugin(PhysicsPropsPlugin);
 */
